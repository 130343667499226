import anime from 'animejs/lib/anime.es.js';

import { Component, OnInit, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { GameService } from '../../../services/game.service';
import { DialogService } from './../../../components/dialog/dialog.service';
import { AudioService } from './../../../services/audio.service';

@Component({
    selector: 'app-game-play',
    templateUrl: './game-play.component.html',
    styleUrls: ['./game-play.component.scss'],
})
export class GamePlayComponent implements OnInit, AfterViewInit, OnDestroy {
    public hash: string;
    public quiz: any;
    public questions: any[];
    public state = {
        index: 0,
        results: [],
        score: 0.000,
        q_start_time: 0, // question start time (epoch)
        timer: 0.000,
        answer: undefined
    };
    public settings = {
        cap: 20.000,
        penalty: 10.000,
        delay_answered: 3
    };

    private intervalTimer: any;
    private timeoutProgress: any;
    private carousel: any;

    private gameEnded: boolean = false;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private gameService: GameService,
        private dialogService: DialogService,
        private audioService: AudioService,
    ) {}

    @HostListener('window:resize', [])
    onWindowResize(): void { this.animTransition(0); }

    ngOnInit() {
        // Get Route Params
        this.activatedRoute.parent.parent.params.subscribe(p => {
            this.hash = p.quizHash;
            // Fetch Content
            this.gameService.getQuizDetailsFromHash(this.hash).subscribe(q => {
                this.quiz = q;
                if (this.quiz.lifecycle_status !== 'active') { this.router.navigate(['/g', this.hash, 'menu']); }
                // Get Questions
                this.getQuestions();
                // Start Gameplay
                this.gameStart();
                // Anime Init
                this.animTransition();
            });
        });
    }

    ngAfterViewInit(): void {}

    ngOnDestroy(): void {
        // Indicate that all class methods should cease operation
        this.gameEnded = true;
        // Stop/Clear
        this.carousel.pause();
        clearInterval(this.intervalTimer);
        clearTimeout(this.timeoutProgress);
        this.dialogService.close();
    }

    // Content ---

    getQuestions(): void {
        this.gameService.getQuestions(this.quiz.id).subscribe(questions => {
            this.questions = questions; // this.gameService.shuffleArray(questions); (randomization moved to api)
            this.preloadImages(questions);
        });
    }

    preloadImages(q: any): void {
        if (q.hasOwnProperty('questions')) {
            for (const question of q.questions) {
                if (question.image_url) {
                    const image = new Image();
                    image.src = question.image_url;
                }
            }
        }
    }

    // Trigger Interstitial ---

    triggerInterstitial(callback: any): void {
        const d = { type: 'ad', src: this.quiz.interstitial_urls[this.state.index] };
        this.dialogService.trigger(d, callback);
    }

    // Timer

    @HostListener('window:timer.start', ['$event'])
    timerStart(): void {
        if (this.gameEnded === true) { return; }
        this.state.q_start_time = Date.now();
        this.state.timer = 0.00; // reset
        this.intervalTimer = setInterval(() => this.timerTick(), 10);
    }

    timerTick(): void {
        if (this.gameEnded === true) { return; }
        // this.state.timer += 0.01; // <-- TODO: remove tihs
        this.state.timer = (Date.now() - this.state.q_start_time) / 1000; // difference
        // If time runs out
        if (this.state.timer >= this.settings.cap) {
            this.state.timer = 20; // auto-correct to 20s max
            this.timerUp();
        }
    }

    timerUp(): void {
        if (this.gameEnded === true) { return; }
        this.timerStop();
        this.setScore(false);
        this.recordResults({ answer_id: '0', correct: false }); // 0 = none
        this.progress();
    }

    timerStop(): void {
        if (this.gameEnded === true) { return; }
        clearInterval(this.intervalTimer);
    }

    // Gameplay

    gameStart(): void {
        if (this.gameEnded === true) { return; }
        this.triggerInterstitial(() => {
            this.timerStart();
        });
    }

    onAnswer(a: any): void {
        if (this.gameEnded === true) { return; }
        this.timerStop();
        this.state.answer = a;
        this.setScore(a.correct).then(() => {
            this.recordResults(a).then(() => { // 0 = none
                this.state.timer = 0.000; // IMPORTANT: must happen here
                this.timeoutProgress = setTimeout(() => { this.progress(); }, this.settings.delay_answered * 1000);
            });
        });
    }

    setScore(isCorrect: boolean): Promise<any> {
        if (this.gameEnded === true) { return; }
        return new Promise((resolve) => {
            const penalty = isCorrect ? 0 : this.settings.penalty;
            const sound = isCorrect ? 'positive' : 'negative';
            this.audioService.triggerSound(sound);
            this.state.score += this.state.timer + penalty;
            resolve(null);
        });
    }

    recordResults(a): Promise<any> {
        if (this.gameEnded === true) { return; }
        return new Promise((resolve) => {
            const r = {
                question_id: this.questions[this.state.index].id,
                answer_id: a.id,
                correct: a.correct,
                time: this.state.timer
            };
            this.state.results.push(r);
            resolve(null);
        });
    }

    progress(): void {
        if (this.gameEnded === true) { return; }
        // If questions remain
        // NOTE: `this.state.timer` is reset to 0 in setScore, otherwise the UI will double report as (score + timer) + timer
        if ((this.state.index + 1) !== this.questions.length) {
            this.state.index++;
            this.state.answer = undefined;
            this.animTransition();
            this.gameStart();
            return;
        }
        // Otherwise Game Over
        this.gameOver();
    }

    animTransition(duration?: number): void {
        if (this.gameEnded === true) { return; }
        let offset: number = 0;
        if (this.state.index > 0) { offset = window.innerWidth * this.state.index; }
        this.carousel = anime({
            targets: '#questions .slide',
            translateX: -offset,
            duration: duration || 100,
            easing: 'linear'
        });
    }

    @HostListener('window:game.gameover', ['$event'])
    gameOver(): void {
        if (this.gameEnded === true) { return; }
        // Pass results to service
        this.gameService.setResults({
            score: this.state.score,
            results: this.state.results
        });
        // Route to Game Review
        this.router.navigate(['/g', this.hash, 'game', 'review']);
    }

    // Utility ---

    answerCorrect(): boolean {
        return this.state.answer && this.state.answer.correct;
    }

    answerWrong(): boolean {
        return this.state.answer && !this.state.answer.correct;
    }
}
