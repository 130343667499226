import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AccountService } from './../../../services/brain-bones/account.service';
import { GameService } from '../../../services/game.service';
import { DialogService } from './../../../components/dialog/dialog.service';
import { AudioService } from 'src/app/services/audio.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-game-review',
    templateUrl: './game-review.component.html',
    styleUrls: ['./game-review.component.scss']
})
export class GameReviewComponent implements OnInit, OnDestroy {
    public hash: string;
    public results: any;
    public quiz: any;
    public account: any;

    public accountSub: Subscription;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private accountService: AccountService,
        private gameService: GameService,
        private dialogService: DialogService,
        private audioService: AudioService
    ) {}

    ngOnInit() {
        // Audio
        this.audioService.triggerSound('end');
        // Get Results
        this.getResults();
        // Get Route Params
        this.activatedRoute.parent.parent.params.subscribe(p => {
            this.hash = p.quizHash;
            // Get Quiz Data
            this.gameService.getQuizDetailsFromHash(this.hash).subscribe(q => {
                this.quiz = q;
                // Get account
                this.accountSub = this.accountService.account.subscribe(a => {
                    this.account = a;
                    this.handleResults();
                });
            });
        });
    }

    ngOnDestroy(): void {
        this.accountSub.unsubscribe(); // fix double submit / leaderboard redirect issue
        this.gameService.removeResults();
    }

    getResults(): void {
        this.results = this.gameService.getResults();
        if (!this.results) { this.router.navigate(['/g', this.hash, 'leaderboard']); }
    }

    handleResults(): void {
        if (!this.account || !this.results) { return; }
        this.submitJrScore();
    }

    submitJrScore(): void {
        this.gameService.submitAttempt(this.quiz.id, this.results).subscribe(
            (res) => {
                // Dialog: Admin/Host Warning
                if (this.account.role === 'admin' || (this.account.role === 'host' && this.account.organizations.includes(this.quiz.organization))) {
                    this.dialogService.trigger({
                        type: 'message',
                        title: `${this.account.role} Account Warning`,
                        message: `Your ${this.account.role} account score will be submitted but excluded from the leaderboard.`
                    });
                }
            },
            (e) => { /* error */ },
            () => {
                // On completion, remove results to prevent duplicates submission
                this.gameService.removeResults();
            }
        );
    }
}
