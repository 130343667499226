/* eslint @angular-eslint/directive-selector: 'off' */

// Click audio sfx trigger

import { Directive, HostListener, Input } from '@angular/core';
import { AudioService } from '../services/audio.service';

@Directive({
    selector: '[audio]'
})
export class AudioDirective {
    @Input('audio') sfx: string;

    constructor(
        private audioService: AudioService
    ) {}

    @HostListener('click', []) // '$event'
    clickEvent() { // event
        if (this.sfx) {
            this.audioService.triggerSound(this.sfx);
        }
    }
}
