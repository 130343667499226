<div id="testlab">

    <!-- Nav -->
    <button class="fabMini" routerLink="/menu" style="margin: 0 auto 16px;">
        <i class="fas fa-arrow-left"></i>
    </button>

    <!-- Avatars -->
    <section class="pad">
        <h3>Avatars</h3>
        <!-- https://media.jackpotrising.com/avatars/v4/1.png -->
        <!-- <div style="max-width: 325px">
            <app-avatars [account]="fakeAccount" (update)="avatarChanged = $event"></app-avatars>
        </div> -->
        <pre>avatarChanged: {{avatarChanged | json}}</pre>
    </section>

    <!-- Notifications -->
    <section class="pad">
        <h3>Interstitials</h3>
        <div class="btnSetVert">
            <button (click)="dialogService.trigger({type: 'ad'})">Interstitial</button>
        </div>
        <br>
        <h3>Dialog</h3>
        <div class="btnSetVert">
            <button (click)="dialogService.trigger(dialogMessage)">Message</button>
            <button (click)="dialogService.trigger({type: 'auth'})">Auth</button>
            <button (click)="dialogService.trigger({type: 'account'})">Account</button>
            <button (click)="dialogService.trigger({type: 'support'})">Support</button>
            <button (click)="dialogService.trigger(dialogWebview)">Webview</button>
        </div>
        <br>
        <h3>Snackbar</h3>
        <div class="btnSetVert">
            <button (click)="snackbarService.trigger('The last name must be between 1 and 48 characters in length (400)')">Long text test</button>
            <button (click)="snackbarService.trigger('Toast 1')">Toast 1</button>
            <button (click)="snackbarService.trigger('Toast 2')">Toast 2</button>
            <button (click)="snackbarService.trigger('Toast 3')">Toast 3</button>
        </div>
    </section>

    <!-- Audio -->
    <section class="pad">
        <h3>Audio</h3>
        <div class="btnSetVert">
            <button (click)="audioService.triggerSound('positive')">Play Positive</button>
            <button (click)="audioService.triggerSound('negative')">Play Negative</button>
        </div>
    </section>

    <!-- ProgressBar.js -->
    <!-- https://kimmobrunfeldt.github.io/progressbar.js/ -->
    <section class="pad">
        <h3>Progress Bar</h3>
        <div id="progressContainer"></div>
        <nav class="btnSetHorz">
            <button class="fabMini" (click)="fillSub()"><i class="fas fa-minus"></i></button>
            <button class="fabMini" (click)="fillAdd()"><i class="fas fa-plus"></i></button>
        </nav>
    </section>

    <!-- Inputs -->
    <section class="pad">
        <h3>Inputs</h3>
        <form name="formTest" id="formTest" [formGroup]="formTest" (ngSubmit)="submit()">
            <fieldset>
                <label>
                    <span>Company (disabled)</span>
                    <input type="text" formControlName="company" placeholder="Disable" disabled>
                </label>
            </fieldset>
            <fieldset>
                <label>
                    <span>First name</span>
                    <input type="text" formControlName="first_name" minlength="3" required>
                </label>
            </fieldset>
            <fieldset>
                <label>
                    <span>Address</span>
                    <textarea type="text" formControlName="address1" placeholder="Ex. 100 Main St" required>1600 Amphitheatre Pkwy</textarea>
                </label>
                <label>
                    <span>Address 2</span>
                    <input type="text" formControlName="address2" required>
                </label>
            </fieldset>
            <fieldset>
                <label>
                    <span>City</span>
                    <input type="text" formControlName="city" placeholder="Ex. San Francisco" required>
                </label>
                <label>
                    <span>State</span>
                    <!-- <input type="text" formControlName="state" placeholder="Ex. California" required> -->
                    <select formControlName="state">
                        <option value="TX">Texas</option>
                        <option value="OK">Oklahoma</option>
                        <option value="NY">New York</option>
                    </select>
                </label>
                <label>
                    <span>Postal Code</span>
                    <input type="number" formControlName="zip" placeholder="Ex. 94105" value="94043" maxlength="5" required>
                </label>
            </fieldset>
        </form>
    </section>

    <!-- Avatars -->
    <section class="pad">
        <h3>Avatars</h3>
        <div id="avatars">
            <img class="avatar large bgBlack" [src]="fakeAccount.avatar">
            <img class="avatar medium bgPrimary" [src]="fakeAccount.avatar">
            <img class="avatar small bgAccent" [src]="fakeAccount.avatar">
        </div>
    </section>

    <!-- Buttons -->
    <section class="pad">
        <h3>Buttons</h3>
        <!-- Anchor -->
        <nav class="btnSetVert">
            <a class="button">Anchor</a>
            <a class="button bgPrimary">Anchor</a>
            <a class="button bgAccent">Anchor</a>
            <a class="button bgWhite">Anchor</a>
            <a class="button stroked">Anchor</a>
        </nav>
        <hr>
        <!-- Buttons -->
        <nav class="btnSetVert">
            <button>Button</button>
            <button class="bgPrimary">Button</button>
            <button class="bgAccent">Button</button>
            <button class="bgWhite">Button</button>
            <button class="stroked">Button</button>
        </nav>
        <hr>
        <!-- Icon Anchor -->
        <nav class="btnSetHorz">
            <a class="button fabMini"><i class="fas fa-pencil-alt"></i></a>
            <a class="button fabMini bgPrimary"><i class="fas fa-question"></i></a>
            <a class="button fabMini bgAccent"><i class="fas fa-sync-alt"></i></a>
            <a class="button fabMini stroked"><i class="fas fa-sync-alt"></i></a>
        </nav>
        <hr>
        <!-- Icon Button -->
        <nav class="btnSetHorz">
            <button class="fabMini"><i class="fas fa-pencil-alt"></i></button>
            <button class="fabMini bgPrimary"><i class="fas fa-question"></i></button>
            <button class="fabMini bgAccent"><i class="fas fa-sync-alt"></i></button>
            <button class="fabMini stroked"><i class="fas fa-sync-alt"></i></button>
        </nav>
        <hr>
        <!-- Circle Anchor -->
        <nav class="btnSetHorz">
            <div class="button fab"><span>GO</span></div>
            <div class="button fab stroked"><span>3</span></div>
        </nav>
    </section>

    <!-- Typography -->
    <section class="pad">
        <!-- Headings -->
        <h1>Heading 1</h1>
        <h2>Heading 2</h2>
        <h3>Heading 3</h3>
        <h4>Heading 4</h4>
        <h5>Heading 5</h5>
        <h6>Heading 6</h6>
        <!-- Body -->
        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quia qui fuga consequuntur molestiae? Omnis ducimus eveniet laborum veniam magni illo unde assumenda ex suscipit nihil, magnam dicta placeat aliquid non?</p>
    </section>

    <!-- Horz Rules -->
    <section class="pad">
        <h3>Horizontal Rule</h3>
        <hr>
    </section>
</div>
