import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GameService } from '../../../../services/game.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-question-basic',
    templateUrl: './question-basic.component.html',
    styleUrls: ['./question-basic.component.scss']
})
export class QuestionBasicComponent implements OnInit {
    @Input() model: any;
    @Output() answered: EventEmitter<any> = new EventEmitter<any>();

    public env: any = environment;
    public selected: any;

    constructor(
        private gameService: GameService
    ) {}

    ngOnInit() {
        this.model.choices = this.gameService.shuffleArray(this.model.choices); // shuffle
    }

    setStyle(selected, a): string {
        let style: string;
        if (selected) { style = a.correct === true ? 'bgAccent' : 'bgPrimary'; }
        return style;
    }

    select(a: any): void {
        if (!this.selected) {
            this.selected = a;
            this.answered.emit(a);
        }
    }
}
