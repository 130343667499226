import { Component, OnInit } from '@angular/core';

import { SnackbarService } from './snackbar.service';
import { AudioService } from './../../services/audio.service';

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {
    public message: string;
    public onClose: boolean = false;
    public timer: any;
    public timeout: any;

    constructor(
        private snackbarService: SnackbarService,
        private audioService: AudioService
    ) {}

    ngOnInit() {
        this.snackbarService.messageSubject.subscribe(m => {
            clearTimeout(this.timer);
            this.audioService.triggerSound('appear');
            this.message = m;
            this.timeout = setTimeout(() => { this.close(); }, 3000);
        });
    }

    close(): void {
        clearTimeout(this.timeout);
        this.audioService.triggerSound('dismiss');
        this.onClose = true;
        this.timer = setTimeout(() => {
            this.message = undefined;
            this.onClose = false;
        }, 50);
    }
}
