import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { GameService } from '../services/game.service';
import { SnackbarService } from '../components/snackbar/snackbar.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class QuizStateGuard implements CanActivate, CanActivateChild {

    constructor(
        private router: Router,
        private snackbarService: SnackbarService,
        private gameService: GameService
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.condition(route);
    }

    canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.condition(route);
    }

    condition(route: ActivatedRouteSnapshot): Observable<boolean> {
        const hash = route.parent.parent.params.quizHash;
        if (hash) {
            // Get quiz data, allow route if lifecycle active, otherwise redirect to error page
            return this.gameService.getQuizDetailsFromHash(hash).pipe(
                map(quiz => {
                    const res = quiz.lifecycle_status === 'active';
                    if (!res) {
                        this.router.navigate([`/g/${hash}/menu`]);
                        this.snackbarService.trigger('Sorry, this quiz is not currently active.');
                    }
                    return res;
                }),
                catchError(error => of(false))
            );
        } else {
            return of(false);
        }
    }
}
