<div id="gameReview" class="animFadeIn">

    <!-- Title -->
    <div id="title">
        <div id="account" *ngIf="account">
            <img
                class="avatar" [ngStyle]="{'background': account.color || undefined}"
                [src]="account.avatar || 'https://cdn.brainandbonesllc.com/bb-assets/avatars/0.png'"
            >
        </div>
        <small>Score</small>
        <h2 data-test-id="reviewScore">
            <i class="fas fa-clock empMed"></i>
            {{results ? (results.score | number:'1.3-3')+"s" : "--"}}
        </h2>
        <button data-test-id="playAgainBtn" class="animHover" [routerLink]="['/g', hash, 'menu']" audio="button">Play Again</button>
    </div>

    <!-- Footer -->
    <footer>
        <button data-test-id="leaderboardBtn" class="stroked" [routerLink]="['/g', hash, 'leaderboard']" audio="button">Leaderboard</button>
    </footer>

</div>
