import * as ProgressBar from 'progressbar.js';
import { Component, AfterViewInit, OnChanges, Input, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-progress',
    templateUrl: './progress.component.html',
    styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements AfterViewInit, OnChanges {
    @Input() range: any;

    private bar: any = undefined;
    private isAnimating: boolean = false;

    constructor() {}

    ngAfterViewInit() {
        this.bar = new ProgressBar.Circle('#bar', {
            strokeWidth: 6,
            easing: 'easeInOut',
            duration: 100,
            color: 'white',
            trailColor: 'rgba(255,255,255,0.33)',
            trailWidth: 3,
            svgStyle: 'null'
        });
        this.animate(this.calculatePercentage(this.range.current, this.range.total));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.range && !this.isAnimating) {
            this.isAnimating = true;
            this.animate(this.calculatePercentage(changes.range.currentValue.current, changes.range.currentValue.total));
        }
    }

    calculatePercentage(current: number, total: number): number {
        return current / total;
    }

    animate(a: any) {
        if (this.bar) {
            this.bar.animate(a, () => { this.isAnimating = false; });
        }
    }
}
