<div class="questionBasic">
    <section id="question">
        <h1>{{model.text}}</h1>
    </section>
    <section id="answers" class="btnSetVert flexAlignCenter">
        <button *ngFor="let a of model.choices" (click)="select(a)" [ngClass]="setStyle(selected, a)">
            {{a.text}}
            <!-- <i class="fas fa-asterisk iconSmall empMed marginLeft8" *ngIf="!env.production && a.correct"></i> -->
        </button>
    </section>
</div>
