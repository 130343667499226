<section id="wrap">

    <!-- Card -->
    <div id="window" class="card">
        <img id="logo" src="./assets/svg/logo.svg" alt="Speed Trivia"><br>

        <!-- Form -->
        <ng-container *ngIf="status === statusValue.default">
            <form name="formReset" id="formReset" [formGroup]="formReset" (ngSubmit)="submit()">
                <fieldset>
                    <p>Minimum of 8 characters.</p>
                </fieldset>
                <fieldset>
                    <label>
                        <span class="textLeft">Enter New Password</span>
                        <input data-test-id="passwordField" type="password" formControlName="password" autocomplete="off" minlength="8" required>
                    </label>
                    <label>
                        <span class="textLeft">Confirm New Password</span>
                        <input data-test-id="confirmField" type="password" formControlName="confirm_password" autocomplete="off" minlength="8" required>
                    </label>
                    <p class="error" *ngIf="validatePasswordMismatch()">Passwords do not match.</p>
                </fieldset>
                <!-- <fieldset> -->
                    <!-- <pre>hash: {{hash | json}}</pre> -->
                    <!-- <pre>form: {{formReset.value | json}}</pre> -->
                <!-- </fieldset> -->
                <fieldset>
                    <button data-test-id="submitBtn" type="submit" class="bgPrimary w100" [disabled]="!formReset.valid || validatePasswordMismatch()">Reset Password</button>
                </fieldset>
            </form>
        </ng-container>


        <!-- Submitting -->
        <g-container *ngIf="status === statusValue.submitting">
            <app-loader></app-loader>
        </g-container>

        <!-- On Success -->
        <g-container *ngIf="status === statusValue.success">
            <div id="success">
                <h3>Password Updated!</h3>
                <p class="empMed">Launch your game URL to begin!</p>
            </div>
        </g-container>
    </div>

</section>
