<!-- Modals -->
<app-dialog></app-dialog>
<app-snackbar></app-snackbar>

<!-- Turn Message -->
<div id="turn">
    <h3><i class="fas fa-redo-alt marginRight8 animSpin"></i>Rotate Device</h3>
</div>

<!-- Layers -->
<div id="layers">
    <div id="routerOutlet">
        <router-outlet></router-outlet>
    </div>
    <canvas id="space" appSpace></canvas>
</div>
