<form name="formLogin" id="formLogin" class="animFadeIn" [formGroup]="formLogin" (ngSubmit)="submit()">
    <fieldset>
        <p class="empMed textCenter">Already have an account? Enter your credentials below.</p><br>
        <label>
            <span>Email</span>
            <input data-test-id="emailFormField" type="email" formControlName="email" placeholder="Enter Email" autocomplete="off" minlength="5" maxlength="254" required>
        </label>
        <label>
            <span>Password</span>
            <input data-test-id="passwordFormField" type="password" formControlName="password" placeholder="Enter Password" autocomplete="off" minlength="8" required>
        </label>
    </fieldset>
    <div class="btnSetHorz">
        <button data-test-id="forgotPasswordBtn" type="button" class="button stroked" (click)="forgotPassword()">Forgot</button>
        <button data-test-id="loginBtn" type="submit" class="button" [disabled]="!formLogin.valid" audio="button">Login</button>
    </div>
</form>
