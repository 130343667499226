import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from './../../../../services/brain-bones/auth.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

    public stepState: number = 0;
    public formRegister: FormGroup = this.fb.group({
        avatar: [undefined],
        color: [undefined],
        username: [undefined, [Validators.required, Validators.pattern(/^[a-zA-Z0-9_]*$/), Validators.minLength(3), Validators.maxLength(32)]],
        first_name: [undefined, [Validators.required, Validators.maxLength(48)]],
        last_name: [undefined, [Validators.required, Validators.maxLength(48)]],
        email: [undefined, [Validators.required, Validators.email, Validators.minLength(5), Validators.maxLength(254)]],
        password: [undefined, [Validators.required, Validators.minLength(8)]],
        confirm_password: [undefined, [Validators.required, Validators.minLength(8)]],
        accept_terms: [false]
    });

    constructor(
        private fb: FormBuilder,
        private authService: AuthService
    ) {}

    ngOnInit() {}

    stepperBack(): void { this.stepState--; }
    stepperNext(): void { this.stepState++; }

    validatePasswordMismatch(): boolean {
        return this.formRegister.value.password !== this.formRegister.value.confirm_password;
    }

    patchForm(formControl: string, value: string): void {
        this.formRegister.patchValue({ [formControl]: value, });
    }

    submit(): void {
        this.authService.register(this.formRegister.value, () => {
            this.closeModal.emit();
        });
    }
}
