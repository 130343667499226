// https://angular-academy.com/angular-jwt/

import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class ApiTokenInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService
    ) {}

    // Intercept HTTP traffic, set global headers, handle 401 status events
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Override Headers
        request = this.setGlobalHeaders(request);
        // Handle Request
        return next.handle(request).pipe(
            catchError(error => {
                // If HTTP error and status is 401
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    // If not /token/ (login) endpoint
                    if (!error.url.includes('/token/')) {
                        return this.handle401Error(request, next);
                    }
                }
                return throwError(error);
            })
        );
    }

    // Set Global Headers
    // -> Content-Type: application/json
    // -> Authorization (if token available)
    setGlobalHeaders(request: HttpRequest<any>, token?: string): HttpRequest<any> {
        const newHeaders: any = {
            'Content-Type': request.headers.get('Content-Type') || 'application/json',
        };
        if (this.authService.hasToken()) {
            newHeaders.Authorization = `Bearer ${token || this.authService.getToken()}`;
        }
        return request.clone({ setHeaders: newHeaders });
    }

    // Handle 401 errors for failed auth by refreshing token
    handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // Trigger Refresh
        return this.authService.refreshToken().pipe(
            switchMap(res => {
                if (res && res.access) {
                    // Save Refreshed Access Token to Cookie
                    this.authService.savedRefreshedToken(res.access);
                    // Handle Updated Request w/ New Headers
                    return next.handle(this.setGlobalHeaders(request, res.access));
                }
            }));
    }
}
