import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from './../../../../services/brain-bones/auth.service';
import { SnackbarService } from './../../../snackbar/snackbar.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

    public formLogin: FormGroup = this.fb.group({
        email: [undefined, [Validators.required, Validators.email, Validators.minLength(5), Validators.maxLength(254)]],
        password: [undefined, [Validators.required, Validators.minLength(8)]],
    });

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private snackbarService: SnackbarService
    ) {}

    ngOnInit(): void {}

    forgotPassword(): void {
        const email: string = window.prompt('Forgot your password? Enter Your Email below.');
        if (email && email.includes('@') && email.includes('.')) {
            this.authService.forgotPassword({email}).subscribe(res => {
                this.snackbarService.trigger('Success. Check your email please.');
            });
        } else {
            this.snackbarService.trigger('Invalid email. Please try again.');
        }
    }

    submit(): any {
        const payload = this.formLogin.value;
        payload.email = payload.email.toLowerCase().trim();
        payload.password = payload.password.trim();
        this.authService.login(payload, () => { this.closeModal.emit(); });
    }
}
