import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {
    public messageSubject = new Subject<string>();

    constructor() {}

    trigger(msg: string): void {
        this.messageSubject.next(msg);
    }
}
