import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { LocalStorageService } from '../local-storage.service';
import { ApiService } from './api.service';
import { SnackbarService } from './../../components/snackbar/snackbar.service';

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    public account: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

    constructor(
        private localStorageService: LocalStorageService,
        private apiService: ApiService,
        private snackbarService: SnackbarService
    ) {
        // Retrieve account data from local storage on init
        this.account.next(this.getLocalStorageAccount() || undefined);
    }

    getLocalStorageAccount(): any {
        return this.localStorageService.getObject('st_account');
    }

    getUserRole(): string {
        return this.getLocalStorageAccount() ? this.getLocalStorageAccount().role : undefined;
    }

    getAccount(): void {
        this.apiService.get('/account/').subscribe(res => {
            this.localStorageService.setObject('st_account', res);
            this.account.next(res);
        });
    }

    updateAccount(payload: any): void {
        this.apiService.patch('/account/', payload).subscribe(res => {
            this.localStorageService.setObject('st_account', res);
            this.account.next(res);
            this.snackbarService.trigger('Account updated');
        });
    }

    changePassword(payload: any): Observable<any> {
        return this.apiService.put(`/account/password/`, payload);
    }
}
