<div id="gamePlay">

    <!-- Sections -->
    <section id="questions">
        <!-- Loop -->
        <div class="slide" *ngFor="let q of questions; index as i">

            <!-- Q: Basic -->
            <ng-container *ngIf="!q.image_url">
                <app-question-basic [model]="q" (answered)="onAnswer($event)"></app-question-basic>
            </ng-container>

            <!-- Q: Image -->
            <ng-container *ngIf="q.image_url">
                <app-question-image [model]="q" (answered)="onAnswer($event)"></app-question-image>
            </ng-container>

        </div>
    </section>

    <!-- User Interface -->
    <section id="ui">

        <!-- Dial -->
        <div id="dial">
            <!-- Timer -->
            <div class="face">
                <app-progress [range]="{current: settings.cap - state.timer, total: settings.cap}"></app-progress>
            </div>
            <!-- Answer: Correct -->
            <div id="dialCorrect" class="face bgAccent animFadeIn" *ngIf="answerCorrect()">
                <i class="fas fa-check-circle animHover"></i>
            </div>
            <!-- Answer: Wrong -->
            <div id="dialWrong" class="face bgPrimary animFadeIn" *ngIf="answerWrong()">
                <i class="fas fa-times-circle animHover"></i>
            </div>
        </div>

        <!-- Progress -->
        <ul class="col" id="progress" title="Progress">
            <ng-container *ngFor="let q of questions; index as i">
                <li class="dot" [ngClass]="{'active': state.index === i}"></li>
            </ng-container>
        </ul>

        <!-- Score -->
        <div class="col" id="score" title="Score">
            <p [ngClass]="{'colorAccent': answerCorrect(), 'colorPrimary': answerWrong()}">
                <i class="fas fa-clock empMed marginRight8"></i>
                <strong>{{state.score + state.timer | number:'1.3-3'}}s</strong>
            </p>
        </div>

    </section>

    <!-- Debug -->
    <!-- <div id="debug"><pre>{{state | json}}</pre></div> -->

</div>
