import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

import { AuthService } from './../../services/brain-bones/auth.service';
import { SnackbarService } from './../../components/snackbar/snackbar.service';

enum statusValue {default, submitting, success};

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    public hash: string;
    public formReset: FormGroup = this.fb.group({
        password: [undefined, [Validators.required, Validators.minLength(8)]],
        confirm_password: [undefined, [Validators.required, Validators.minLength(8)]],
    });
    public status: number = statusValue.default;
    public statusValue: any = statusValue;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private authService: AuthService,
        private snackbarService: SnackbarService
    ) {}

    ngOnInit() {
        this.route.params.subscribe(p => this.hash = p.hash);
    }

    validatePasswordMismatch(): boolean {
        return this.formReset.value.password !== this.formReset.value.confirm_password;
    }

    submit(): void {
        // Logic gate to prevent double submission
        if (this.status === statusValue.default) {
            this.status = statusValue.submitting;
            // Submit to API
            const payload = {hash: this.hash, password: this.formReset.value.password};
            this.authService.resetPassword(payload).subscribe(res => {
                this.status = statusValue.success;
                this.snackbarService.trigger('Please login with your new credentials.');
            });
        }
    }
}
