import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GameService } from 'src/app/services/game.service';

@Component({
    selector: 'app-leaderboards-scroll',
    templateUrl: './leaderboards-scroll.component.html',
    styleUrls: ['./leaderboards-scroll.component.scss']
})
export class LeaderboardsScrollComponent implements OnInit {

    public quiz: any;
    public leaderboard: any[] = [];
    private refreshTimer: number = 5; // minutes

    constructor(
        private activatedRoute: ActivatedRoute,
        public gameService: GameService,
    ) {}

    ngOnInit(): void {
        // Route Params
        this.activatedRoute.params.subscribe(p => {
            // Quiz Details
            this.gameService.getQuizDetails(p.quizId).subscribe(q => {
                this.quiz = q;
                this.getLeaderboard();
                setInterval(() => { this.getLeaderboard(); }, 60*1000*this.refreshTimer);
            });
        });
    }

    getLeaderboard(): void {
        this.gameService.getLeaderboard(this.quiz.id).subscribe(lb => this.leaderboard = lb);
    }
}
