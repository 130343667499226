<div id="leaderboard" *ngIf="quiz">

    <!-- Details -->
    <section id="details">
        <!-- Header -->
        <header class="animFadeIn" [routerLink]="['/g', hash, 'menu']">
            <a data-test-id="backBtn" class="button fabMini animHover" audio="button">
                <i class="fas fa-arrow-left"></i>
            </a>
            <img id="logo" [src]="quiz.logo_url" alt="logo">
            <h5>Main Menu</h5>
        </header>
        <!-- Conversion -->
        <a id="conversion" class="animFadeIn" (click)="clickConversion()">
            <img class="animHover" [src]="quiz.conversion_url" alt="conversion">
        </a>
        <!-- Sponsor -->
        <a id="sponsor" class="animFadeIn" (click)="clickSponsor()" target="_blank">
            <img [src]="quiz.sponsor_url" alt="sponsor">
        </a>
    </section>

    <!-- Lists -->
    <section id="lists" class="animFadeIn">
        <div id="rootCard" class="card">

            <!-- Tabs -->
            <ul class="tabs" *ngIf="account">
                <li (click)="tabAuthIndex = 0" [ngClass]="{'active': tabAuthIndex === 0}" audio="switch">
                    <i class="fas fa-list-ol"></i>
                    <span>Leaderboard</span>
                </li>
                <li (click)="tabAuthIndex = 1" [ngClass]="{'active': tabAuthIndex === 1}" audio="switch">
                    <i class="fas fa-user-circle"></i>
                    <span>Attempts</span>
                </li>
            </ul>

            <!-- 0: Rankings -->
            <section id="rankings" class="animFadeIn" *ngIf="tabAuthIndex === 0">
                <p class="empMed marginAuto" *ngIf="!leaderboard.length">Be the first to enter!</p>
                <nav *ngIf="leaderboard.length">
                    <li *ngFor="let r of leaderboard; index as i">
                        <section data-test-id="position" class="position empMed">
                            {{gameService.nth(r.rank)}}
                        </section>
                        <section class="image">
                            <img class="avatar" [ngStyle]="{backgroundColor: r.user.color}" [src]="r.user.avatar">
                        </section>
                        <section class="user">
                            <strong>{{r.user.username || "(username)"}}</strong>
                        </section>
                        <section class="score">
                            <div class="col">
                                <h5>{{r.score | number:'1.3-3'}}s</h5>
                                <p class="empMed" *ngIf="quiz.prizes[i]">{{quiz.prizes[i].name}}</p>
                            </div>
                            <div class="col" *ngIf="quiz.prizes[i]">
                                <button class="fabMini stroked" (click)="prizeDetails(i)" *ngIf="quiz.prizes[i]">
                                    <i class="fas fa-gift"></i>
                                </button>
                            </div>
                        </section>
                    </li>
                </nav>
            </section>

            <!-- 1: Attempts -->
            <section id="attempts" class="animFadeIn" *ngIf="tabAuthIndex === 1">
                <p class="empMed marginAuto" *ngIf="!attempts.length">No attempts made.</p>
                <ng-container *ngIf="attempts.length">
                    <div id="stats" class="flexGrid flexNonResponsive" *ngIf="playerStats">
                        <div class="col card textCenter">
                            <p class="empMed">
                                <i class="fas fa-crown iconSmall marginRight8"></i>
                                Best
                            </p>
                            <h4 id="bestAtempt">{{(playerStats.best_attempt.score || "0") | number:'1.3-3'}}s</h4>
                        </div>
                        <div class="col card textCenter">
                            <p class="empMed">
                                <i class="fas fa-clock iconSmall marginRight8"></i>
                                Latest
                            </p>
                            <h4 id="latestAttempt">{{(playerStats.latest_attempt.score || "0") | number:'1.3-3'}}s</h4>
                        </div>
                    </div>
                    <ul id="list">
                        <li *ngFor="let a of attempts; index as i">
                            <span class="count empMed">{{gameService.nth(i+1)}}</span>
                            <span><h5>{{a.score | number:'1.3-3'}}s</h5></span>
                            <span class="spacer"></span>
                            <span data-test-id="attemptDate" class="empMed">{{a.created_at | date:"short"}}</span>
                        </li>
                    </ul>
                </ng-container>
            </section>

        </div>
    </section>

</div>
