import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from './../../../environments/environment';

import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

import { SnackbarService } from './../../components/snackbar/snackbar.service';
import { DialogService } from './../../components/dialog/dialog.service';

interface HttpOptions { headers?: HttpHeaders; params?: HttpParams }

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    public isLoading = new BehaviorSubject(false);
    private baseUrl = environment.apiBaseUrl;
    private timeout = 15000; // 15s (extended for bulk import)

    constructor(
        private http: HttpClient,
        private snackbarService: SnackbarService,
        private dialogService: DialogService
    ) {}

    // Helper Methods ---

    // https://angular.io/api/common/http/HttpHeaders
    setHeaders(headersObject): HttpHeaders {
        return new HttpHeaders(headersObject);
    }

    // https://angular.io/api/common/http/HttpParams
    setHttpParams(paramsObject): HttpParams {
        let httpParams = new HttpParams();
        Object.keys(paramsObject).forEach((key) => {
            if (paramsObject[key] !== undefined) {
                httpParams = httpParams.append(key, paramsObject[key]);
            }
        });
        return httpParams;
    }

    // Set HttpOptions - ex: {headers: XXX, params: XXX}
    // Note: use `Object.assign({},{})` to merge objects
    setOptions(options: any): HttpOptions {
        if (!options) { return {}; }
        return {
            headers: options.headers ? this.setHeaders(options.headers) : null,
            params: options.params ? this.setHttpParams(options.params) : null
        };
    }

    verifyEndpointTrailingSlash(endpoint: string): string {
        const endpointValid: boolean = endpoint.endsWith('/');
        if (!endpointValid) { console.error('WARNING: endpoint missing trailing slash', endpoint); }
        return endpointValid ? endpoint : `${endpoint}/`;
    }

    // Standard CRUD ---
    // https://angular.io/api/common/http/HttpRequest
    // !IMPORTANT! - trailing slashes for endpoints required for Django (ex: /foo/)

    get(endpoint: string, options?: any): Observable<any> {
        endpoint = this.verifyEndpointTrailingSlash(endpoint);
        return this.http.get<any>(this.baseUrl+endpoint, this.setOptions(options))
            .pipe(
                catchError(err => this.errorHandler(err)),
                timeout(this.timeout)
            );
    }

    post(endpoint: string, body: any, options?: any): Observable<any> {
        endpoint = this.verifyEndpointTrailingSlash(endpoint);
        return this.http.post<any>(this.baseUrl+endpoint, JSON.stringify(body), this.setOptions(options))
            .pipe(
                catchError(err => this.errorHandler(err)),
                timeout(this.timeout)
            );
    }

    put(endpoint: string, body: any, options?: any): Observable<any> {
        endpoint = this.verifyEndpointTrailingSlash(endpoint);
        return this.http.put<any>(this.baseUrl+endpoint, JSON.stringify(body), this.setOptions(options))
            .pipe(
                catchError(err => this.errorHandler(err)),
                timeout(this.timeout)
            );
    }

    patch(endpoint: string, body: any, options?: any): Observable<any> {
        endpoint = this.verifyEndpointTrailingSlash(endpoint);
        return this.http.patch<any>(this.baseUrl+endpoint, JSON.stringify(body), this.setOptions(options))
            .pipe(
                catchError(err => this.errorHandler(err)),
                timeout(this.timeout)
            );
    }

    delete(endpoint: string, options?: any): Observable<any> {
        endpoint = this.verifyEndpointTrailingSlash(endpoint);
        return this.http.delete<any>(this.baseUrl+endpoint, this.setOptions(options))
            .pipe(
                catchError(err => this.errorHandler(err)),
                timeout(this.timeout)
            );
    }

    // Error Handler ---

    errorHandler(e): any {
        let errMessage = e.error.detail || e.error.error;
        // Catch all for non-standard errors // e.error.email || e.error.username
        if (errMessage === undefined) { errMessage = e.error[Object.keys(e.error)[0]]; }
        // 4xx Errors
        if (e.status >= 400 && e.status < 500) {
            this.snackbarService.trigger(`${errMessage || 'Error Occured'} (${e.status})`);
        // 5xx or Other Errors
        } else {
            this.dialogService.trigger({
                title: `${e.statusText || 'Server Error'} (${e.status})`,
                message: errMessage || 'Sorry, but an error has occured on the server.'
            });
        }
        return throwError(e);
    }
}
