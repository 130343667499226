<div class="avatars">

    <section class="current" *ngIf="mode === 0">
        <p class="textCenter empMed">Customize you avatar and color.</p>
        <div class="actions">
            <img data-test-id="avatarBtn" class="animHover" [src]="currentAvatar" [ngStyle]="{'background': currentColor}" (click)="mode = 1">
            <button data-test-id="colorBtn" class="fabMini" [ngStyle]="{'background': currentColor}" (click)="mode = 2">
                <i class="fas fa-palette"></i>
            </button>
        </div>
    </section>

    <section class="selectAvatar" *ngIf="mode === 1">
        <nav class="avatarList">
            <li *ngFor="let a of avatarsList; index as i" (click)="selectAvatar(a)">
                <img [src]="a" [ngStyle]="{'background': currentColor}">
            </li>
        </nav>
    </section>

    <section class="selectColor" *ngIf="mode === 2">
        <nav>
            <li *ngFor="let c of colorsList" [ngStyle]="{'background': c}" (click)="selectColor(c)"></li>
        </nav>
    </section>

</div>
