import { BehaviorSubject, EMPTY } from 'rxjs';
// @ts-ignore
import Hashids from 'hashids';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ApiService } from './brain-bones/api.service';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GameService {
    private quiz: any;

    constructor(
        private localStorageService: LocalStorageService,
        private apiService: ApiService,
    ) {}

    // Quiz & Questions ---

    getQuizDetailsFromHash(quizHash: string): Observable<any> {
        const lsQuiz: any = this.getQuizLocalStorage(quizHash);
        // From service (data store)
        if (this.quiz) {
            return new BehaviorSubject<any>(this.quiz);
        }
        // From localstorage
        else if (lsQuiz !== null) {
            this.quiz = lsQuiz;
            return new BehaviorSubject<any>(this.quiz);
        }
        // From HTTP endpoint -- note: caches to service and localstorage w/ tap
        else {
            return this.getQuizDetails(this.decodeHash(quizHash)).pipe(
                tap(q => {
                    this.quiz = q;
                    this.setQuizLocalStorage(quizHash, q);
                })
            );
        }
    }

    getQuizDetails(quizId: number): Observable<any> {
        return this.apiService.get(`/speedtrivia/quizzes/${quizId}/`);
    }

    setQuizLocalStorage(quizHash: string, quizData: any): void {
        this.localStorageService.setObject(quizHash, quizData);
    }

    getQuizLocalStorage(quizHash: string): any {
        this.quiz = this.localStorageService.getObject(quizHash);
        return this.quiz;
    }

    getQuestions(quizId: number): Observable<any> {
        return this.apiService.get(`/speedtrivia/quizzes/${quizId}/questions/`);
    }

    // Stats

    getPlayerStats(quizId: number, playerId: number): Observable<any> {
        return this.apiService.get(`/speedtrivia/quizzes/${quizId}/user/${playerId}/`);
    }

    // Latest Score (localstorage) ---

    getResults(): any {
        return this.localStorageService.getObject('score');
    }

    setResults(payload: any): any {
        this.localStorageService.setObject('score', payload);
    }

    removeResults(): void {
        this.localStorageService.remove('score');
    }

    // Attempts ---

    getUsersAttempts(quizId: number, userId: number): Observable<any> {
        return this.apiService.get(`/speedtrivia/quizzes/${quizId}/user/${userId}/attempts/`);
    }

    submitAttempt(quizId: number, payload: any): Observable<any> {
        return this.apiService.post(`/speedtrivia/quizzes/${quizId}/attempts/`, payload);
    }

    // Leaderboard & Prizes ---

    getLeaderboard(quizId: number): Observable<any> {
        return this.apiService.get(`/speedtrivia/quizzes/${quizId}/leaderboard/`);
    }

    // Tracking

    reportTracking(quizId: number, payload: any): Observable<any> {
        return this.apiService.post(`/speedtrivia/quizzes/${quizId}/track/`, payload);
    }

    // Utility

    // https://hashids.org/
    decodeHash(quizHash: string): number {
        const hashids = new Hashids('k^zRr$\!,~Qb=)4b', 10);
        return parseInt(hashids.decode(quizHash)[0]);
    }

    // https://gist.github.com/guilhermepontes/17ae0cc71fa2b13ea8c20c94c5c35dc4
    shuffleArray(arr: any): any {
        return arr
            .map(a => [Math.random(), a])
            .sort((a, b) => a[0] - b[0])
            .map(a => a[1]);
    }

    // https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
    nth(n): string {
        return `${n}${['st','nd','rd'][((n+90)%100-10)%10-1]||'th'}`;
    }
}
