import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
    @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
    public tabAuthIndex: number = 0;

    constructor() {}
    ngOnInit() {}

    closeDialog(): void {
        this.closeModal.emit();
    }
}
