import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

import { Observable, EMPTY } from 'rxjs';

import { LocalStorageService } from '../local-storage.service';
import Cookies from 'js-cookie';
import { AccountService } from './account.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private apiService: ApiService,
        private localStorageService: LocalStorageService,
        private accountService: AccountService
    ) {}

    getToken(): string {
        return Cookies.get('st_access');
    }

    getRefreshToken(): string {
        return Cookies.get('st_refresh');
    }

    hasToken(): boolean {
        return this.getToken() !== undefined ? true : false;
    }

    hasRefreshToken(): boolean {
        return this.getRefreshToken() !== undefined ? true : false;
    }

    setExpireMinutes(minutes: number): Date {
        return new Date(new Date().getTime() + minutes * 60 * 1000);
    }

    register(payload: any, callback: any): void {
        this.apiService.post(`/account/`, payload).subscribe(res => {
            this.login({email: payload.email, password: payload.password}, callback);
        });
    }

    login(payload: any, callback: any): void {
        this.apiService.post(`/token/`, payload).subscribe(res => {
            callback();
            this.onAuthSuccess(res);
        });
    }

    forgotPassword(payload: any): Observable<any> {
        return this.apiService.post(`/account/forgot/`, payload);
    }

    resetPassword(payload: any): Observable<any> {
        return this.apiService.post(`/account/reset/`, payload);
    }

    onAuthSuccess(res: any): void {
        Cookies.set('st_access', res.access, { expires: this.setExpireMinutes(5), sameSite: 'lax' });
        Cookies.set('st_refresh', res.refresh, { expires: 1, sameSite: 'lax' }); // 1 day
        this.accountService.getAccount();
    }

    refreshToken(): Observable<any> {
        // If: refresh token hasn't expired retrive,
        if (this.hasRefreshToken()) {
            return this.apiService.post(`/token/refresh/`, {refresh: this.getRefreshToken()});
        }
        // Trigger logout and return EMPTY observable cancel request
        this.logout();
        return EMPTY;
    }

    savedRefreshedToken(token: string) {
        Cookies.remove('st_access');
        Cookies.set('st_access', token, { expires: this.setExpireMinutes(5), sameSite: 'lax' });
    }

    logout(): void {
        this.accountService.account.next(undefined);
        // Remove Cookies
        Cookies.remove('st_access');
        Cookies.remove('st_refresh');
        // Remove from LocalStorage
        // this.localStorageService.remove('st_account');
        this.localStorageService.clear(); // required to clear unique hash values
    }
}
