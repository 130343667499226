import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-game',
    template: '<router-outlet></router-outlet>',
})
export class GameComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
