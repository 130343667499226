import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild } from '@angular/router';
import { AccountService } from '../services/brain-bones/account.service';

import { SnackbarService } from '../components/snackbar/snackbar.service';

@Injectable({
    providedIn: 'root'
})
export class RoleAdminGuard implements CanActivate, CanActivateChild {

    constructor(
        private router: Router,
        private accountService: AccountService,
        private snackbarService: SnackbarService
    ) {}

    canActivate() {
        return this.adminCondition();
    }

    canActivateChild() {
        return this.adminCondition();
    }

    isAdmin(): boolean {
        return this.accountService.getUserRole() === 'admin';
    }

    adminCondition(): boolean {
        if (this.isAdmin() === false) {
            this.router.navigate(['/admin-required']);
            this.snackbarService.trigger('Sorry, you cannot access this page.');
            return false;
        }
        return true;
    }
}
