import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    public dialogSubject = new BehaviorSubject<any>(undefined);
    public callback: any;

    constructor() {}

    trigger(d: any, callback?: any): void {
        this.dialogSubject.next(d);
        if (callback) { this.callback = callback; }
    }

    close(): void {
        this.dialogSubject.next(undefined);
    }
}
