import anime from 'animejs/lib/anime.es.js';

import { Component, OnInit, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { GameService } from '../../../services/game.service';
import { AudioService } from './../../../services/audio.service';

@Component({
    selector: 'app-game-start',
    templateUrl: './game-start.component.html',
    styleUrls: ['./game-start.component.scss']
})
export class GameStartComponent implements OnInit, AfterViewInit, OnDestroy {
    public hash: string;
    public quiz: any;

    private moon: any;
    private rocket: any;
    private planet: any;

    constructor(
        private activatedRoute: ActivatedRoute,
        private gameService: GameService,
        private audioService: AudioService
    ) {}

    @HostListener('window:resize', [])
    initAnimation(): void {
        const path = anime.path('.path circle');
        this.moon = anime({
            targets: '.moon',
            translateX: path('x'),
            translateY: path('y'),
            rotate: path('angle'),
            easing: 'linear',
            duration: 10000,
            loop: true
        });
        // Rocket
        this.rocket = anime({
            targets: '.rocket',
            translateX: path('x'),
            translateY: path('y'),
            rotate: path('angle'),
            easing: 'linear',
            duration: 5000,
            loop: true
        });
        // Planet
        this.planet = anime({
            targets: '.planet svg',
            rotate: [0, 360], // degs
            duration: 60*1000,
            easing: 'linear',
            loop: true
        });
    }

    ngOnInit(): void {
        this.audioService.triggerSound('start');
        // Get Route Params
        this.activatedRoute.parent.parent.params.subscribe(p => {
            this.hash = p.quizHash;
            this.gameService.getQuizDetailsFromHash(this.hash).subscribe(q => this.quiz = q);
        });
    }

    ngAfterViewInit(): void {
        this.initAnimation();
    }

    ngOnDestroy(): void {
        anime.remove('.moon');
        anime.remove('.rocket');
        anime.remove('.planet svg');
    }
}
