<form name="formRegister" id="formRegister" class="animFadeIn" [formGroup]="formRegister" (ngSubmit)="submit()">
    <input type="hidden" formControlName="avatar">
    <input type="hidden" formControlName="color">

    <!-- Steps -->
    <div id="steps">

        <!-- 0: Avatar -->
        <fieldset *ngIf="stepState === 0">
            <section class="avatarWrap">
                <app-avatars
                    [register]="true"
                    [account]="formRegister.value.avatar && formRegister.value.color ? formRegister.value : undefined"
                    (avatar)="patchForm('avatar', $event)"
                    (color)="patchForm('color', $event)"
                ></app-avatars>
            </section>
        </fieldset>

        <!-- 1: Info -->
        <fieldset *ngIf="stepState === 1">
            <label>
                <span>First Name</span>
                <input data-test-id="firstNameField" matInput type="text" placeholder="First Name" formControlName="first_name" autocomplete="off" maxlength="48" required>
            </label>
            <label>
                <span>Last Name</span>
                <input data-test-id="lastNameField" type="text" placeholder="Last Name" formControlName="last_name" autocomplete="off" maxlength="48" required>
            </label>
            <label>
                <span>Public Username</span>
                <input
                    data-test-id="usernameField"
                    matInput type="text" placeholder="Username" formControlName="username" autocomplete="off" required
                    pattern="^[a-zA-Z0-9_]*$" minlength="3" maxlength="32"
                >
                <small class="hint">Letters and numbers only. Appears on leaderboard.</small>
            </label>
        </fieldset>

        <!-- 2: Account -->
        <fieldset *ngIf="stepState === 2">
            <label>
                <span>Email</span>
                <input data-test-id="emailField" type="email" placeholder="Email" formControlName="email" autocomplete="off" minlength="5" maxlength="254" required>
            </label>
            <label>
                <span>Set Password (min. 8 characters)</span>
                <input data-test-id="passwordField" type="password" formControlName="password" autocomplete="off" minlength="8" required>
            </label>
            <label>
                <span>Confirm Password</span>
                <input data-test-id="confirmPasswordField" type="password" formControlName="confirm_password" autocomplete="off" minlength="8" required>
            </label>
            <p class="error" *ngIf="validatePasswordMismatch()">Passwords do not match.</p>
        </fieldset>

        <!-- 3: Legal -->
        <fieldset *ngIf="stepState === 3">
            <section class="iframeWrap">
                <iframe src="https://brainandbonesllc.com/legal" frameborder="0"></iframe>
            </section>
            <section>
                <label class="textCenter">
                    <input data-test-id="legalCheckbox" type="checkbox" formControlName="accept_terms">
                    <small>I agree to the <a href="https://brainandbonesllc.com/legal" target="_blank">Privacy Policy</a> and <a href="https://brainandbonesllc.com/legal/terms" target="_blank">Terms of Usage</a>.</small>
                </label>
            </section>
            <section *ngIf="validatePasswordMismatch() || !formRegister.valid">
                <p data-test-id="formValidationError" class="error">Missing or invalid data provided. Please go back and resolve this.</p>
            </section>
        </fieldset>

        <!-- Submit -->
        <div class="btnSetHorz">
            <button data-test-id="stepBackBtn" class="stroked" type="button" (click)="stepperBack()" [disabled]="stepState <= 0" audio="button">
                <i class="fas fa-arrow-left"></i>
            </button>
            <ng-container *ngIf="stepState < 3">
                <button data-test-id="regNextBtn" type="button" (click)="stepperNext()" audio="button">Next</button>
            </ng-container>
            <ng-container *ngIf="stepState >= 3">
                <button
                    data-test-id="registerBtn"
                    type="submit" audio="button"
                    [disabled]="!formRegister.valid || !formRegister.value.accept_terms || validatePasswordMismatch()"
                >Register</button>
            </ng-container>
        </div>

    </div>
</form>
