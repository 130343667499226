<div id="auth">

    <!-- Tabs -->
    <ul class="tabs">
        <li (click)="tabAuthIndex = 0" [ngClass]="{'active': tabAuthIndex === 0}" audio="switch">
            <i class="fas fa-user-circle"></i>
            <span>Register</span>
        </li>
        <li (click)="tabAuthIndex = 1" [ngClass]="{'active': tabAuthIndex === 1}" audio="switch">
            <i class="fas fa-sign-in-alt"></i>
            <span>Login</span>
        </li>
    </ul>

    <!-- Content -->
    <app-register (closeModal)="closeDialog()" *ngIf="tabAuthIndex === 0"></app-register>
    <app-login (closeModal)="closeDialog()" *ngIf="tabAuthIndex === 1"></app-login>

</div>
