import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

// Environment
import { environment } from '../environments/environment';

// Pipes
import { HtmlPipe } from './pipes/html.pipe';
import { SafePipe } from './pipes/safe.pipe';

// Provider Services
import { ApiService } from './services/brain-bones/api.service';
import { ApiTokenInterceptor } from './services/brain-bones/api-token-interceptor.service';
import { ApiLoadingInterceptor } from './services/brain-bones/api-loading-interceptor.service';

// Angular Router
import { AppRoutingModule } from './app-routing.module';

// Directies
import { SpaceDirective } from './directives/space.directive';
import { AudioDirective } from './directives/audio.directive';

// Components: App
import { AppComponent } from './app.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { DialogComponent } from './components/dialog/dialog.component';
    import { AuthComponent } from './components/dialog/auth/auth.component';
        import { LoginComponent } from './components/dialog/auth/login/login.component';
        import { RegisterComponent } from './components/dialog/auth/register/register.component';
    import { AccountComponent } from './components/dialog/account/account.component';
    import { SupportComponent } from './components/dialog/support/support.component';
import { AvatarsComponent } from './components/avatars/avatars.component';
import { ProgressComponent } from './components/progress/progress.component';

// Components: Layouts
import { WrapperComponent } from './views/wrapper.component';

// Components: Views
import { SplashComponent } from './views/splash/splash.component';
import { MainMenuComponent } from './views/main-menu/main-menu.component';
import { LeaderboardsComponent } from './views/leaderboards/leaderboards.component';
import { GameComponent } from './views/game/game.component';
    import { GameStartComponent } from './views/game/game-start/game-start.component';
    import { GamePlayComponent } from './views/game/game-play/game-play.component';
        import { QuestionBasicComponent } from './views/game/game-play/question-basic/question-basic.component';
        import { QuestionImageComponent } from './views/game/game-play/question-image/question-image.component';
    import { GameReviewComponent } from './views/game/game-review/game-review.component';
import { TestlabComponent } from './views/testlab/testlab.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { VideoComponent } from './views/video/video.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { EmptyComponent } from './views/empty/empty.component';
import { LeaderboardsScrollComponent } from './views/leaderboards-scroll/leaderboards-scroll.component';
import { ScrollDirective } from './directives/scroll.directive';

@NgModule({
    declarations: [
        // Pipes
        HtmlPipe,
        SafePipe,

        // Directives
        SpaceDirective,
        AudioDirective,

        // Components
        AppComponent,
        LoaderComponent,
        SnackbarComponent,
        DialogComponent,
            AuthComponent,
                LoginComponent,
                RegisterComponent,
            AccountComponent,
            SupportComponent,
        AvatarsComponent,
        ProgressComponent,

        // Layouts
        // ...

        // Views
        WrapperComponent,
        SplashComponent,
        MainMenuComponent,
        LeaderboardsComponent,
        GameComponent,
            GameStartComponent,
            GamePlayComponent,
                QuestionBasicComponent,
                QuestionImageComponent,
            GameReviewComponent,
        TestlabComponent,
        NotFoundComponent,
        VideoComponent,
        ResetPasswordComponent,
        EmptyComponent,
        LeaderboardsScrollComponent,
        ScrollDirective,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule, ReactiveFormsModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),

        // Routing
        AppRoutingModule,
    ],
    providers: [
        ApiService,
        { provide: HTTP_INTERCEPTORS, useClass: ApiTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ApiLoadingInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
