// https://developer.chrome.com/blog/autoplay/#best-practices-for-web-developers

import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GameService } from './../../services/game.service';
import { SnackbarService } from './../../components/snackbar/snackbar.service';

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, AfterViewInit {
    @ViewChild('video', {static: false}) video: ElementRef;
    public videoPlayer: HTMLVideoElement;

    public hash: string;
    public quiz: any;
    public currentTime: number = 0;
    public duration: number = 100;

    public showProgressBar: boolean = false;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private gameService: GameService,
        private snackbarService: SnackbarService
    ) {}

    ngOnInit(): void {
        // Get Route Params
        this.activatedRoute.parent.params.subscribe(p => {
            this.hash = p.quizHash;
            this.gameService.getQuizDetailsFromHash(this.hash).subscribe(q => this.quiz = q);
        });
    }

    ngAfterViewInit(): void {
        this.videoPlayer = this.video.nativeElement;
        this.handleVideoPlayer();
        this.handleProgressBar();
    }

    playVideo(): void {
        this.videoPlayer.play();
        this.videoPlayer.volume = 0.5;
    }

    handleVideoPlayer(): void {
        this.videoPlayer.volume = 0;
        this.videoPlayer.onended = () => { this.navigateToGameStart(); };
        // Debugging: throw console error and snackbar message if autoplay fails
        const promiseVideoPlay = this.videoPlayer.play();
        if (promiseVideoPlay !== undefined) {
            promiseVideoPlay.catch(err => {
                console.error(err);
                this.snackbarService.trigger('Tap video to start playing.');
            });
        }
    }

    handleProgressBar(): void {
        // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/loadeddata_event
        this.videoPlayer.addEventListener('loadeddata', (e) => {
            this.showProgressBar = true;
            this.duration = Math.floor(this.videoPlayer.duration);
            this.videoPlayer.ontimeupdate = (e) => { this.currentTime = Math.ceil(this.videoPlayer.currentTime); };
        });
    }

    navigateToGameStart(): void {
        this.router.navigate(['/g', this.hash, 'game', 'start']);
    }
}
