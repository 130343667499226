import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild } from '@angular/router';
import { AuthService } from '../services/brain-bones/auth.service';
import { SnackbarService } from './../components/snackbar/snackbar.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {
    private hash: string;

    constructor(
        private router: Router,
        private authService: AuthService,
        private snackbarService: SnackbarService
    ) {}

    canActivate() {
        return this.checkAuth();
    }

    canActivateChild() {
        return this.checkAuth();
    }

    checkAuth() {
        if (this.authService.hasRefreshToken()) {
            return true;
        } else {
            this.authService.logout(); // NOTE: fallback since auth-token-interceptor not always triggering refresh
            this.snackbarService.trigger('Login required to proceed');
            this.router.navigate(['/login-required'])
            return false;
        }
    }
}
