import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { interval } from 'rxjs';

import { AudioService } from './services/audio.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    public title = 'Speed Trivia';

    constructor(
        public updates: SwUpdate,
        private audioService: AudioService
    ) {
        this.audioService.init();
        this.initServiceWorker();
    }

    // If Service Worker enabled: check for updates on interval, then prompt user
    initServiceWorker(): void {
        if (environment.production === true) {

            // Check for Updates
            this.updates.checkForUpdate(); // on load
            interval(30000).subscribe(() => this.updates.checkForUpdate()); // every 30s

            // When Update Available
            this.updates.available.subscribe(event => {
                this.updates.activateUpdate().then(() => {
                    document.location.reload(); // reload page
                });
            });
        }
    }

}
