import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'app-avatars',
    templateUrl: './avatars.component.html',
    styleUrls: ['./avatars.component.scss']
})
export class AvatarsComponent implements OnInit {
    @Input() register: boolean;
    @Input() account: any;

    @Output() avatar: EventEmitter<string> = new EventEmitter<string>();
    @Output() color: EventEmitter<string> = new EventEmitter<string>();

    public mode: number = 0; // 0: current, 1: avatar, 2: colors

    public avatarBaseUrl: string = 'https://cdn.brainandbonesllc.com/bb-assets/avatars/'; // #.png
    public avatarAmount: number = 20; // 0-20
    public avatarsList: any[] = [];
    public currentAvatar: string = `${this.avatarBaseUrl+this.randRange(1, this.avatarAmount-1)}.png`;

    public colorsList: any[] = [
        '#dc3545', // red
        '#fd7e14', // orange
        '#ffc107', // yellow
        '#28a745', // green
        `#20c997`, // teal
        `#17a2b8`, // cyan
        '#007bff', // blue
        '#6610f2', // indigo
        '#6f42c1', // purple
        `#e83e8c`, // pink
    ];
    public currentColor: string = this.colorsList[this.randRange(0, this.colorsList.length-1)];

    constructor() {}

    ngOnInit() {
        this.generateAvatarList();
        // On Account, overwrite default values
        if (this.account !== undefined) {
            this.currentAvatar = this.account.avatar;
            this.currentColor = this.account.color;
        }
        // Emit default values on register
        if (this.register === true) {
            this.avatar.emit(this.currentAvatar);
            this.color.emit(this.currentColor);
        }
    }

    randRange(start: number, end: number): number {
        return Math.floor(Math.random() * (end + 1)) + start;
    };

    generateAvatarList(): void {
        for (let i = 1; i <= this.avatarAmount; i++) {
            const avatar = `${this.avatarBaseUrl+i}.png`;
            this.avatarsList.push(avatar);
        }
    }

    selectAvatar(a: string): void {
        this.currentAvatar = a;
        this.avatar.emit(a);
        this.mode = 0;
    }

    selectColor(c: string): void {
        this.currentColor = c;
        this.color.emit(c);
        this.mode = 0;
    }
}
