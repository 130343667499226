import { tsParticles } from 'tsparticles';
import { Directive, ElementRef } from '@angular/core';
import { PARTICLES } from './particles';

@Directive({
    selector: '[appSpace]'
})
export class SpaceDirective {

    private theme: any = PARTICLES;

    constructor(el: ElementRef) {
        tsParticles.load(el.nativeElement.id, this.theme);
    }
}
