import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
    public tabAuthIndex: number = 0;
    public video: any = {
        poster: 'https://cdn.brainandbonesllc.com/bb-assets/support/tutorial_poster.jpg',
        src: 'https://cdn.brainandbonesllc.com/bb-assets/support/tutorial.mp4'
    };

    constructor(
        private fb: FormBuilder,
    ) {}

    ngOnInit() {}
}
