<div id="splash">

    <!-- Loader -->
    <app-loader *ngIf="!quiz"></app-loader>

    <!-- Brand -->
    <section id="brand" *ngIf="quiz">

        <header [style.background-image]="'url('+quiz.branding.backsplash_url+')'">
            <img id="logo" [src]="quiz.branding.logo_url">
        </header>

        <progress [value]="countdown.current" [max]="countdown.max"></progress>

        <footer>
            <h5>{{quiz.branding.name}}</h5>
        </footer>

    </section>

</div>

