import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

import { AccountService } from './../../../services/brain-bones/account.service';
import { AuthService } from './../../../services/brain-bones/auth.service';
import { SnackbarService } from '../../snackbar/snackbar.service';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
    @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

    public tabAuthIndex: number = 0; // 0,1,2

    public account: any;
    public formAccount: FormGroup = this.fb.group({
        // NOTE: do not allow username to be edit
        first_name: [null, [Validators.required]],
        last_name: [null, [Validators.required]],
        // TODO: provided logic to confirm these are unique:
        email: [{value: null, disabled: true}], // disabled
        username: [{value: null, disabled: true}] // disabled
    });
    public formPassword: FormGroup = this.fb.group({
        old_password: [null, [Validators.required, Validators.minLength(8)]],
        new_password: [null, [Validators.required, Validators.minLength(8)]],
        confirm_new_password: [null, [Validators.required, Validators.minLength(8)]],
    });

    constructor(
        private fb: FormBuilder,
        private accountService: AccountService,
        private authService: AuthService,
        private snackbarService: SnackbarService,
    ) {}

    ngOnInit() {
        this.accountService.account.subscribe(a => {
            this.account = a;
            if (a) { this.formAccount.patchValue(a); }
        });
    }

    patchAccount(payload: any): void {
        this.accountService.updateAccount(payload);
    }

    submitFormAccount(): void {
        this.accountService.updateAccount(this.formAccount.value);
        this.closeModal.emit();
    }

    submitFormPassword(): void {
        this.accountService.changePassword(this.formPassword.value).subscribe(res => {
            this.formPassword.reset(); // clear
            this.snackbarService.trigger('Password updated successfully!');
            this.closeModal.emit();
        });
    }

    logout(): void {
        this.authService.logout();
        this.closeModal.emit();
    }
}
