import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Guards
import { AuthGuardService } from './guards/auth.guard';
import { QuizStateGuard } from './guards/quiz-state.guard';
import { RoleAdminGuard } from './guards/role-admin.guard';

// Components: Layouts
import { WrapperComponent } from './views/wrapper.component';

// Components: Views
import { SplashComponent } from './views/splash/splash.component';
import { MainMenuComponent } from './views/main-menu/main-menu.component';
import { VideoComponent } from './views/video/video.component';
import { GameComponent } from './views/game/game.component';
    import { GameStartComponent } from './views/game/game-start/game-start.component';
    import { GamePlayComponent } from './views/game/game-play/game-play.component';
    import { GameReviewComponent } from './views/game/game-review/game-review.component';
import { LeaderboardsComponent } from './views/leaderboards/leaderboards.component';
import { LeaderboardsScrollComponent } from './views/leaderboards-scroll/leaderboards-scroll.component';
import { TestlabComponent } from './views/testlab/testlab.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { EmptyComponent } from './views/empty/empty.component';

// Routes
const routes: Routes = [

    // Root
    { path: '', redirectTo: '404', pathMatch: 'full' },

    // ************************************************************************
    // Redirect for legacy splash screen -- TODO: remove after fully migrated
    { path: 'launch/:quizHash', redirectTo: 'g/:quizHash', pathMatch: 'full' },
    // ************************************************************************

    // Game Wrapper (persists hash)
    {path: 'g/:quizHash', component: WrapperComponent, children: [

        // Main Menu
        { path: '', component: SplashComponent },

        // Main Menu
        { path: 'menu', component: MainMenuComponent },

        // Video
        { path: 'video', component: VideoComponent },

        // Game
        {
            path: 'game', component: GameComponent, canActivateChild: [AuthGuardService, QuizStateGuard], children: [
                { path: '', redirectTo: 'start', pathMatch: 'full' },
                { path: 'start', component: GameStartComponent },
                { path: 'play', component: GamePlayComponent },
                { path: 'review', component: GameReviewComponent },
            ]
        },

        // Leaderboard
        { path: 'leaderboard', component: LeaderboardsComponent }

    ]},

    // QR Redirects
    { path: 'qr', redirectTo: '/launch/Ke2rvMJo68?utm_source=qrcode', pathMatch: 'full' },
    { path: 'leaderboard-scroll/:quizId', component: LeaderboardsScrollComponent },

    // Misc Paths
    { path: 'empty', component: EmptyComponent }, // for video recording
    { path: 'reset/:hash', component: ResetPasswordComponent },
    { path: 'testlab', component: TestlabComponent, canActivate: [RoleAdminGuard] },
    { path: '**', component: NotFoundComponent },

];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
