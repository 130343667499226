import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DateTimeService {
    private tickerInterval: any;

    constructor() { }

    dateTimeFormat(d) {
        return moment(d).format('M-DD-YY @ h:mm A');
    }

    timeTicker(timestamp: string, callback: any): BehaviorSubject<string> {
        const fromNowObs: BehaviorSubject<string> = new BehaviorSubject<string>('Starts Soon');
        this.tickerInterval = setInterval(() => {
            const start = moment();
            const end = moment(timestamp);
            const difference = end.diff(start);
            if (difference <= 0 ) {
                clearInterval(this.tickerInterval);
                callback();
            } else {
                fromNowObs.next(`Starts ${end.from(start)}`);
            }
        }, 1000);
        return fromNowObs;
    }
}
