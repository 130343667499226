import anime from 'animejs/lib/anime.es.js';
import { Directive, AfterViewInit, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[appScroll]'
})
export class ScrollDirective implements AfterViewInit {
    @Input() rows: number = 0;
    private delay: number = 5000; // 5s
    private enabled: boolean = true;

    constructor(
        private elem: ElementRef,
    ) {}

    ngAfterViewInit(): void {
        if (this.enabled) {
            setTimeout(() => {
                this.scrollBottom();
            }, 1000);
        }
    }

    scrollBottom(): void {
        anime({
            delay: this.delay,
            targets: this.elem.nativeElement,
            scrollTop: this.elem.nativeElement.scrollHeight - this.elem.nativeElement.clientHeight,
            duration: 1000 * this.rows,
            easing: 'easeInOutQuad'
        }).finished.then(() => {
            this.scrollTop();
        });
    }

    scrollTop(): void {
        anime({
            delay: this.delay,
            targets: this.elem.nativeElement,
            scrollTop: 0,
            duration: 100,
            easing: 'linear'
        }).finished.then(() => {
            this.scrollBottom();
        });
    }

}
