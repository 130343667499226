<div id="account" *ngIf="account">

    <!-- Tabs -->
    <ul class="tabs">
        <li (click)="tabAuthIndex = 0" [ngClass]="{'active': tabAuthIndex === 0}" audio="switch">
            <i class="fas fa-user-circle"></i>
            <span>Avatar</span>
        </li>
        <li (click)="tabAuthIndex = 1" [ngClass]="{'active': tabAuthIndex === 1}" audio="switch">
            <i class="fas fa-user-cog"></i>
            <span>Account</span>
        </li>
        <li (click)="tabAuthIndex = 2" [ngClass]="{'active': tabAuthIndex === 2}" audio="switch">
            <i class="fas fa-key"></i>
            <span>Password</span>
        </li>
    </ul>

    <!-- 0: Avatars -->
    <div class="animFadeIn" *ngIf="tabAuthIndex === 0">
        <section class="avatarWrap">
            <app-avatars
                [register]="false"
                [account]="account"
                (avatar)="patchAccount({avatar: $event})"
                (color)="patchAccount({color: $event})"
            ></app-avatars>
        </section>
        <section class="avatarWrap textCenter">
            <button id="btnLogout" class="stroked" (click)="logout()" audio="button">
                <i class="fas fa-sign-out-alt iconSmall marginRight8"></i>
                Logout
            </button>
        </section>
    </div>

    <!-- 1: Account -->
    <form class="animFadeIn" name="formAccount" [formGroup]="formAccount" (ngSubmit)="submitFormAccount()" *ngIf="tabAuthIndex === 1">
        <fieldset>
            <!-- NOTE: do not allow username to be edit -->
            <label>
                <span>First Name</span>
                <input type="text" placeholder="Enter First Name" formControlName="first_name" required>
            </label>
            <label>
                <span>Last Name</span>
                <input type="text" placeholder="Enter Last Name" formControlName="last_name" required>
            </label>
            <label>
                <span>Username</span>
                <input type="text" placeholder="Enter Username" formControlName="username" disabled>
            </label>
            <label>
                <span>Email</span>
                <input type="email" placeholder="Enter Email" formControlName="email" disabled>
            </label>
        </fieldset>
        <fieldset class="textCenter">
            <button type="submit" [disabled]="!formAccount.valid" audio="button">Update</button>
        </fieldset>
    </form>

    <!-- 2: Password -->
    <form class="animFadeIn" name="formPassword" [formGroup]="formPassword" (ngSubmit)="submitFormPassword()" *ngIf="tabAuthIndex === 2">
        <fieldset>
            <label><span>Enter Previous Password</span>
                <input type="password" placeholder="******" formControlName="old_password" minlength="8" required>
            </label>
        </fieldset>
        <fieldset>
            <label><span>Set New Password (min. 8 characters)</span>
                <input #password type="password" placeholder="******" formControlName="new_password" minlength="8" required>
            </label>
            <label><span>Confirm New Password</span>
                <input #confirm type="password" placeholder="******" formControlName="confirm_new_password" minlength="8" required>
            </label>
        </fieldset>
        <fieldset *ngIf="
            formPassword.value.new_password && formPassword.value.confirm_new_password &&
            formPassword.value.new_password !== formPassword.value.confirm_new_password
        ">
            <p class="error">Passwords entered do not match.</p>
        </fieldset>
        <fieldset class="textCenter">
            <button type="submit" [disabled]="password.value !== confirm.value || !formPassword.valid" audio="button">Update</button>
        </fieldset>
    </form>

</div>
