import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AccountService } from './../../services/brain-bones/account.service';
import { GameService } from './../../services/game.service';
import { DialogService } from './../../components/dialog/dialog.service';

@Component({
    selector: 'app-leaderboards',
    templateUrl: './leaderboards.component.html',
    styleUrls: ['./leaderboards.component.scss']
})
export class LeaderboardsComponent implements OnInit {
    public hash: string;
    public quiz: any;
    public account: any;

    public tabAuthIndex: number = 0;
    public leaderboard: any[] = [];
    public attempts: any[] = [];
    public playerStats: any;

    constructor(
        private activatedRoute: ActivatedRoute,
        public gameService: GameService,
        private accountService: AccountService,
        public dialogService: DialogService,
    ) {}

    ngOnInit(): void {
        // Get Route Params
        this.activatedRoute.parent.params.subscribe(p => {
            this.hash = p.quizHash;
            this.gameService.getQuizDetailsFromHash(this.hash).subscribe(q => {
                this.quiz = q;
                // Get Account
                this.accountService.account.subscribe(a => {
                    this.account = a;
                    if (a) {
                        this.getPlayerStats();
                        this.getAttempts(a.id);
                    }
                });
                // Get Page Data
                this.getLeaderboard();
            });
        });
    }

    getLeaderboard(): void {
        this.gameService.getLeaderboard(this.quiz.id).subscribe(lb => this.leaderboard = lb);
    }

    getAttempts(userId: number): void {
        this.gameService.getUsersAttempts(this.quiz.id, userId).subscribe(a => this.attempts = a);
    }

    getPlayerStats(): void {
        this.gameService.getPlayerStats(this.quiz.id, this.account.id).subscribe(ps => this.playerStats = ps);
    }

    prizeDetails(i: number): void {
        if (this.quiz.prizes.length && this.quiz.prizes[i]) {
            const d: any = {
                type: 'message',
                icon: 'fas fa-gift',
                title: this.quiz.prizes[i].name,
                message: this.quiz.prizes[i].description
            };
            this.dialogService.trigger(d);
        }
    }

    clickSponsor(): void {
        if (this.quiz.sponsor_iframe) {
            const d: any = { type: 'webview', title: 'Sponsor', webview: this.quiz.sponsor_link};
            this.dialogService.trigger(d);
        } else {
            const win = window.open(this.quiz.sponsor_link, '_blank');
            win.focus();
        }
    }

    clickConversion(): void {
        if (this.quiz.conversion_iframe) {
            const d: any = { type: 'webview', title: 'Special Offer', webview: this.quiz.conversion_link};
            this.dialogService.trigger(d);
        } else {
            const win = window.open(this.quiz.conversion_link, '_blank');
            win.focus();
        }
    }
}
