// https://github.com/goldfire/howler.js
import { Howl, Howler } from 'howler';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AudioService {
    public music = {};
    public sfx: any = {
        start: new Howl({ src: ['./assets/audio/sfx/game-start.wav'], volume: 0.75 }),
        end: new Howl({ src: ['./assets/audio/sfx/game-end.wav'], volume: 0.75 }),
        positive: new Howl({ src: ['./assets/audio/sfx/positive.wav'], volume: 1 }),
        negative: new Howl({ src: ['./assets/audio/sfx/negative.mp3'], volume: 1 }),
        button: new Howl({ src: [
            './assets/audio/sfx/button-1.wav',
            './assets/audio/sfx/button-2.wav'
        ], volume: 0.5 }),
        appear: new Howl({ src: ['./assets/audio/sfx/appear.wav'], volume: 0.75 }),
        dismiss: new Howl({ src: ['./assets/audio/sfx/dismiss.wav'], volume: 0.75 }),
        switch: new Howl({ src: ['./assets/audio/sfx/switch.wav'], volume: 0.75 }),
        countdown: new Howl({ src: ['./assets/audio/sfx/countdown.mp3'], volume: 0.5, rate: 0.1 }),
    };

    constructor() {
        // Global Settings
        // Howler.volume(0.5);
    }

    randWithinRange(start: number, end: number): number {
        return Math.floor(Math.random() * (end + 1)) + start;
    };

    triggerSound(name: string): void {
        const p = this.sfx[name].play();
        let rand = this.randWithinRange(0.999, 1.001);
        if (name === 'countdown') { rand = 0.5; }
        this.sfx[name].rate(rand, p); // pitch
    }

    stopSound(name: string): void {
        this.sfx[name].play();
    }

    // Triggered on app component to initialize asap
    init(): void {};
}
