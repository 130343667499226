<div id="wrap" *ngIf="quiz" (click)="playVideo()">
    <!-- Mute Text -->
    <div id="muteText"><i class="fas fa-volume-up iconSmall"></i> Tap anywhere to unmute.</div>
    <!-- Video Player -->
    <div id="video">
        <!-- IMPORTANT: must list autoplay first, then muted. This allows autoplay to work. -->
        <video #video controlsList="nodownload" autoplay muted="muted" playsinline preload="metadata" [poster]="quiz.rewarded_video_poster">
            <source [src]="quiz.rewarded_video" type="video/mp4">
            <p>Browser does not support video.</p>
        </video>
    </div>
    <!-- Progress Bar -->
    <progress [value]="currentTime" [max]="duration" *ngIf="showProgressBar"></progress>
</div>
