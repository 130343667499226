<div id="menu" *ngIf="quiz">

    <!-- Account -->
    <section id="account" audio="button" (click)="clickAccount()">
        <div id="pillbox">

                <!-- Logged Out -->
                <ng-container *ngIf="!account">
                    <img class="avatar" [src]="'https://cdn.brainandbonesllc.com/bb-assets/avatars/0.png'">
                </ng-container>

                <!-- Logged In -->
                <ng-container *ngIf="account">
                    <div class="badgeWrap">
                        <span data-test-id="roleBadge" class="badge" *ngIf="['admin','host'].includes(account.role)">
                            <i *ngIf="account.role === 'admin'" class="fas fa-skull-crossbones" title="Admin"></i>
                            <i *ngIf="account.role === 'host'" class="fas fa-star" title="Host"></i>
                        </span>
                        <img class="avatar" [src]="account.avatar" [ngStyle]="{'background': account.color}">
                    </div>
                    <h6 class="animFadeIn">{{account.username || "Loading..."}}</h6>
                </ng-container>

        </div>
    </section>

    <!-- Sponsor -->
    <a id="sponsor" (click)="clickSponsor()">
        <img data-test-id="sponsorImg" [src]="quiz.sponsor_url" alt="sponsor">
    </a>

    <!-- Content -->
    <section id="content" class="animZoomIn">

        <img id="logo" [src]="quiz.logo_url" alt="logo">

        <h2 class="ellipsis w100">{{quiz.name}}</h2>
        <p class="empMed" *ngIf="!quiz.allow_attempts">(Quiz Disabled)</p>

        <p><strong class="animFadeIn" *ngIf="subtext()">{{subtext() | titlecase}}</strong></p>

        <div class="btnSetHorz flexAlignCenter">
            <button data-test-id="goBtn" class="fab" (click)="goButton()" audio="button" [disabled]="setGoButtonState()">
                <span>GO</span>
            </button>
            <button data-test-id="detailsBtn" class="fabMini" (click)="dialogService.trigger(dialogDetails)" title="Details" audio="button">
                <i class="fas fa-gift" *ngIf="quiz.prizes && quiz.prizes.length"></i>
                <i class="fas fa-book-open" *ngIf="!quiz.prizes || !quiz.prizes.length"></i>
            </button>
            <button data-test-id="leaderBoardBtn" class="fabMini" [routerLink]="['/g', hash, 'leaderboard']" title="Leaderboard" audio="button">
                <i class="fas fa-list-ol"></i>
            </button>
            <button data-test-id="supportBtn" class="fabMini" (click)="dialogService.trigger({type: 'support'})" title="Support" audio="button">
                <i class="fas fa-question"></i>
            </button>
        </div>

    </section>

</div>
