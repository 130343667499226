<div id="compDialog" (mousedown)="close(dialog.type)" *ngIf="dialog">

    <!-- Dialog: Modal -->
    <div
        class="animZoomIn" [ngClass]="{animZoomOut: onClose}"
        id="modal"
        (mousedown)="stopPropagation($event)"
        *ngIf="['webview', 'video', 'ad'].includes(dialog.type) === false"
    >
        <button id="btnClose" class="fabMini" (mousedown)="close()"><i class="fas fa-times"></i></button>

        <!-- Icon - 'fas fa-comment-alt' -->
        <div class="icon" *ngIf="dialog.icon">
            <i [ngClass]="dialog.icon"></i>
        </div>

        <!-- Message -->
        <ng-container *ngIf="dialog.type === 'message'">
            <h3 class="ellipsis w100">{{dialog.title}}</h3>
            <p class="empMed">{{dialog.message}}</p>
        </ng-container>

        <!-- HTML -->
        <ng-container *ngIf="dialog.type === 'html'">
            <h3 class="ellipsis w100">{{dialog.title}}</h3>
            <div [innerHTML]="dialog.markup | html">(Missing)</div>
        </ng-container>

        <!-- Auth -->
        <section id="auth" *ngIf="dialog.type === 'auth'">
            <app-auth (closeModal)="close()"></app-auth>
        </section>

        <!-- Account -->
        <ng-container *ngIf="dialog.type === 'account'">
            <app-account (closeModal)="close()"></app-account>
        </ng-container>

        <!-- Support -->
        <!-- (close)="close()" -->
        <ng-container *ngIf="dialog.type === 'support'">
            <app-support></app-support>
        </ng-container>
    </div>

    <!-- Dialog: Webview -->
    <div
        class="animSlideUp" [ngClass]="{animSlideDown: onClose}"
        id="webview"
        (mousedown)="stopPropagation($event)"
        *ngIf="dialog.type === 'webview'"
    >
        <header>
            <h5>{{dialog.title}}</h5>
            <button data-test-id="webviewCloseBtn" class="fabMini" (mousedown)="close()"><i class="fas fa-times"></i></button>
        </header>
        <iframe [src]="dialog.webview | safe" frameborder="0"></iframe>
    </div>

    <!-- Dialog: Interstitial Ad -->
    <div
        class="animSlideUp" [ngClass]="{animSlideDown: onClose}"
        id="ad"
        (mousedown)="stopPropagation($event)"
        *ngIf="dialog.type === 'ad'"
    >
        <img [src]="dialog.src" alt="Interstitial">
        <section id="aside">
            <button class="fab stroked">
                <span data-test-id="interstitialCounter">{{counter.current || counter.default}}</span>
            </button>
        </section>
    </div>

</div>
