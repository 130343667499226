<div id="support">

    <!-- Tabs -->
    <ul class="tabs">
        <li (click)="tabAuthIndex = 0" [ngClass]="{'active': tabAuthIndex === 0}" audio="switch">
            <i class="fas fa-play-circle"></i>
            <span>Tutorial</span>
        </li>
        <li (click)="tabAuthIndex = 1" [ngClass]="{'active': tabAuthIndex === 1}" audio="switch">
            <i class="fas fa-mail-bulk"></i>
            <span>Contact</span>
        </li>
    </ul>

    <!-- 0: Video -->
    <div class="animFadeIn" *ngIf="tabAuthIndex === 0">
        <!-- IMPORTANT: controls="true" required for older versions of iOS -->
        <!-- https://stackoverflow.com/questions/20347352/html5-video-tag-not-working-in-safari-iphone-and-ipad -->
        <video preload="metadata" playsinline controls="true" controlsList="nodownload" [poster]="video.poster">
            <source [src]="video.src" type="video/mp4">
            <p>Browser does not support video.</p>
        </video>
    </div>

    <!-- 1: Email -->
    <div class="animFadeIn" *ngIf="tabAuthIndex === 1">
        <div id="tempSupport">
            <h4>Get Support</h4>
            <p class="empMed">Use the email below to contact support and report any issue you may be experiencing. Make sure to include your full name, account information, what type of device and browser you're playing on, as well as the name or link to the quiz you're participating in.</p>
            <a mat-raised-button class="button textTransformNormal" href="mailto:contact@brainandbonesllc.com"><small>contact@brainandbonesllc.com</small></a>
        </div>
    </div>

</div>
