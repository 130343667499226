// @ts-ignore
import Hashids from 'hashids';

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { GameService } from './../../services/game.service';

@Component({
    selector: 'app-splash',
    templateUrl: './splash.component.html',
    styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {
    public hash: string;
    public quiz: any;
    public countdown: any = {current: 0, max: 3};

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private gameService: GameService,
    ) {}

    ngOnInit(): void {
        // Get Route Params
        this.activatedRoute.params.subscribe(p => {
            // Set hash for redirect
            this.hash = p.quizHash;
            // Get Quiz Details
            this.gameService.getQuizDetailsFromHash(this.hash).subscribe(q => {
                this.quiz = q;
                this.initPreload();
                this.initTracking();
                this.initTimer();
            });
        });
    }

    initPreload(): void {
        // Preload Video
        this.preloadVideo();
        // Preload Images
        this.preloadImage(this.quiz.logo_url);
        this.preloadImage(this.quiz.sponsor_url);
        this.preloadImage(this.quiz.conversion_url);
        this.preloadImage(this.quiz.rewarded_video_poster);
        for (const url of this.quiz.interstitial_urls) {
            if (url.image_url) { this.preloadImage(url.image_url); }
        }
    }

    // https://stackoverflow.com/questions/19251983/dynamically-create-a-html5-video-element-without-it-being-shown-in-the-page
    preloadVideo(): void {
        const video = document.createElement('video');
            video.src = this.quiz.rewarded_video;
            video.preload = 'auto';
    }

    // https://stackoverflow.com/questions/3646036/preloading-images-with-javascript
    preloadImage(url: string): void {
        const image = new Image();
        image.src = url;
    }

    initTracking(): void {
        this.activatedRoute.queryParams.subscribe(p => {
            const payload: any = { user_agent: navigator.userAgent };
            if (p.utm_source) { payload.utm_source = p.utm_source; }
            this.gameService.reportTracking(this.quiz.id, payload).subscribe();
        });
    }

    initTimer(): void {
        const intervalCountdown: any = setInterval(() => {
            if (this.countdown.current < this.countdown.max) {
                this.countdown.current++;
            } else {
                clearInterval(intervalCountdown);
                this.router.navigate(['/g', this.hash, 'menu']);
                // this.countdown.current = 0;
                // setTimeout(() => { this.timer(); }, this.countdown.max);
            }
        }, 1000);
}
}
