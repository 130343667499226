import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from './dialog.service';
import { AudioService } from './../../services/audio.service';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
    public dialog: any;
    public dialogOpen: boolean = false;
    public counter: any = {default: 3, current: undefined};
    public onClose: boolean = false;
    private adTimeout: any;

    constructor(
        private router: Router,
        private dialogService: DialogService,
        private audioService: AudioService
    ) {}

    ngOnInit() {
        // If route changes, close the dialog
        this.router.events.subscribe(e => {
            if (this.dialogOpen === true) { this.close(); }
        });
        // Subscribe to dialog subject
        this.dialogService.dialogSubject.subscribe(d => {
            clearTimeout(this.adTimeout);
            if (d) {
                this.dialogOpen = true;
                this.audioService.triggerSound('appear');
            }
            this.dialog = d;
            // if (d && d.type === 'video') { this.initVideoPlayer(); }
            if (d && d.type === 'ad') { this.initAd(); }
        });
    }

    stopPropagation(e: Event): void {
        e.stopPropagation();
    }

    initAd() {
        this.adTimeout = setTimeout(() => {
            this.counter.current = this.counter.default;
            // this.audioService.triggerSound('countdown');
            const intervalAd = setInterval(() => {
                if (this.counter.current - 1 > 0) {
                    // this.audioService.triggerSound('countdown');
                    this.counter.current--;
                } else {
                    if (this.dialogService.callback) { this.dialogService.callback(); }
                    clearInterval(intervalAd);
                    this.close();
                    setTimeout(() => { this.counter.current = this.counter.default; }, 1000);
                }
            }, 1000);
        }, 500); // anim
    }

    // NOTE: use (mousedown) to prevent select->drag->mouseup triggering close() event
    @HostListener('window:dialog.close', ['$event'])
    close(type?: string): void {
        console.log('close() triggered', type);
        if (type === 'ad') { return; } // disable manual close for interstitial
        this.dialogOpen = false;
        this.audioService.triggerSound('dismiss');
        this.onClose = true;
        setTimeout(() => {
            this.onClose = false;
            this.dialogService.close();
        }, 200);
    }
}
