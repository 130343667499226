<div id="leaderboardScroll" appScroll [rows]="leaderboard.length">
    <ng-container *ngIf="quiz">

        <!-- Header -->
        <header>
            <div id="logo">
                <img [src]="quiz.logo_url" alt="logo">
            </div>
            <div id="title">
                <h1 class="ellipsis">{{quiz.name}}</h1>
            </div>
            <div id="cta" class="textRight">
                <h2>speedtriviagame.com</h2>
            </div>
        </header>

        <!-- Rankings -->
        <section id="rankings">

            <!-- No Results -->
            <div id="placeholder" *ngIf="!leaderboard.length">
                <div class="iconWrap"><i class="fas fa-list-ul"></i></div>
                <p class="mat-body-2">Be the first to place on the leaderboard!</p>
            </div>

            <!-- Results -->
            <table *ngIf="leaderboard.length">
                <thead>
                    <tr>
                        <th>Rank</th>
                        <th class="textLeft">Player</th>
                        <th *ngIf="quiz.prizes.length > 0">Prize</th>
                        <th>Score</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of leaderboard; index as i">
                        <td>
                            <span class="empMed">{{gameService.nth(row.rank)}}</span>
                            <div *ngIf="row.tie">
                                <small class="empMed">Tied</small>
                            </div>
                        </td>
                        <td>
                            <div class="player">
                                <img [ngStyle]="{backgroundColor: row.user.color}" [src]="row.user.avatar">
                                <span>{{row.user.username}}</span>
                            </div>
                        </td>
                        <td *ngIf="quiz.prizes.length > 0">
                            <span class="empMed">
                                <ng-container *ngIf="quiz.prizes[i]">{{quiz.prizes[i].name}}</ng-container>
                                <ng-container *ngIf="!quiz.prizes[i]">&bull;</ng-container>
                            </span>
                        </td>
                        <td><strong>{{row.score | number:'1.3-3'}}s</strong></td>
                    </tr>
                </tbody>
            </table>

        </section>

        <!-- <pre>{{quiz | json}}</pre> -->
        <!-- <pre>{{leaderboard | json}}</pre> -->

    </ng-container>
</div>
