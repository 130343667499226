import * as ProgressBar from 'progressbar.js';

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { AudioService } from '../../services/audio.service';
import { SnackbarService } from './../../components/snackbar/snackbar.service';
import { DialogService } from './../../components/dialog/dialog.service';

@Component({
    selector: 'app-testlab',
    templateUrl: './testlab.component.html',
    styleUrls: ['./testlab.component.scss']
})
export class TestlabComponent implements OnInit, AfterViewInit {

    public bar: any;
    public fill: number = 0.0;
    public formTest = this.fb.group({
        company: ['Acme, Inc.', Validators.required],
        first_name: ['', [Validators.min(3), Validators.required]],
        address1: ['123 Street', Validators.required],
        address2: ['Apt 456', Validators.required],
        city: ['Dallas', Validators.required],
        state: ['TX', Validators.required],
        zip: ['75201', Validators.required],
    });

    public fakeAccount: any = {
        avatar: 'https://cdn.brainandbonesllc.com/bb-assets/avatars/0.png',
        color: '#BADA55'
    };
    public avatarChanged: any;

    // Dialogs
    public dialogMessage: any = {
        type: 'message',
        title: 'This is a really long title',
        message: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit.'
    };
    public dialogWebview: any = { type: 'webview', title: 'Terms & Conditions', webview: 'https://terms.jackpotrising.com/' };

    constructor(
        private fb: FormBuilder,
        public audioService: AudioService,
        public snackbarService: SnackbarService,
        public dialogService: DialogService
    ) {}

    ngOnInit() {}

    ngAfterViewInit() {
        this.bar = new ProgressBar.Circle('#progressContainer', {
            strokeWidth: 3,
            easing: 'easeInOut',
            duration: 200,
            color: '#F8F6E9',
            trailColor: '#374953',
        });
        this.barAnimate();
    }

    fillAdd(): void {
        this.fill += 0.1;
        this.barAnimate();
    }

    fillSub(): void {
        this.fill -= 0.1;
        this.barAnimate();
    }

    barAnimate(): void {
        this.bar.animate(this.fill); // 0.0 to 1.0
    }

    submit() {
        console.log(this.formTest.value);
    }
}
