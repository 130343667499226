import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AccountService } from './../../services/brain-bones/account.service';
import { GameService } from './../../services/game.service';
import { DialogService } from './../../components/dialog/dialog.service';
import { SnackbarService } from './../../components/snackbar/snackbar.service';
import { DateTimeService } from './../../services/date-time.service';

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {
    public hash: string;
    public quiz: any;
    public account: any;
    public playerStats: any;
    public attemptCount: number;

    public dialogDetails: any = { type: 'html', icon: 'fas fa-book-open', title: 'Details', markup: undefined };
    public cta: any = { disabled: true, text: 'Unknown', action: () => { } };
    public timeTicker: any;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private accountService: AccountService,
        private gameService: GameService,
        public dialogService: DialogService,
        private snackbarService: SnackbarService,
        private dateTimeService: DateTimeService
    ) { }

    ngOnInit(): void {
        // Get Route Params
        this.activatedRoute.parent.params.subscribe(p => {
            this.hash = p.quizHash;
            // Chain the following calls sequentially
            this.getQuizDetails(() => {
                this.getAccount(() => {
                    this.getPlayerStats();
                });
            });
        });
    }

    getQuizDetails(callback?: any): void {
        this.gameService.getQuizDetailsFromHash(this.hash).subscribe(q => {
            this.quiz = q;
            this.genDialogDetails();
            if (this.quiz.lifecycle_status === 'upcoming') { this.setTicker(); }
            if (callback) { callback(); }
        });
    }

    getAccount(callback?: any): void {
        // Get Account Details
        this.accountService.account.subscribe(a => {
            this.account = a;
            if (callback) { callback(); }
        });
    }

    getPlayerStats(): void {
        if (!this.quiz || !this.account) { return; }
        this.playerStats = undefined;
        this.gameService.getPlayerStats(this.quiz.id, this.account.id).subscribe(ps => this.playerStats = ps);
    }

    setGoButtonState(): boolean {
        if (this.account === undefined) { return false; }             // always allow in logged out state
        if (this.quiz.allow_attempts === false) { return true; }      // owner org subscription not active
        if (this.quiz.lifecycle_status !== 'active') { return true; } // quiz not within start/end duration window
        if (this.quiz.canceled_at) { return true; }                   // quiz was cancelled
        if (this.attemptCount === 0) { return true; }                 // no attempts remaining
        return false;
    }

    // Subtext ---

    subtext(): string {
        let s: string;
        if (this.quiz && this.quiz.canceled_at) { return 'Quiz cancelled by host.'; }
        if (!this.account) { return 'Login to begin!'; }
        if (this.quiz && this.account) {
            switch (this.quiz.lifecycle_status) {
                // Upcoming
                case('upcoming'):
                    s = this.timeTicker;
                    break;
                // Active
                case('active'):
                    if (this.account) {
                        if (this.playerStats) {
                            this.attemptCount = this.quiz.max_attempts - this.playerStats.total_attempts;
                            s = this.attemptCount > 0 ? `${this.attemptCount} Attempt(s) Remaining` : 'Out of Attempts';
                        }
                    }
                    break;
                // Completed/Archived
                default: s = this.quiz.lifecycle_status;
            }
        }
        return s;
    }

    setTicker(): void {
        // Ticker will cause quiz to refresh when ticker ends
        this.dateTimeService
            .timeTicker(this.quiz.start_date, () => { this.getQuizDetails(); })
            .subscribe(tt => this.timeTicker = tt);
    }

    // Dialogs ---

    // TODO: create proper templates for this HTML markup
    genDialogDetails(): void {
        // Details
        const start = this.dateTimeService.dateTimeFormat(this.quiz.start_date);
        const end = this.dateTimeService.dateTimeFormat(this.quiz.end_date);
        this.dialogDetails.markup = `
            <p class="textCenter">
                <span class="empMed">${this.quiz.description}</span><br>
                <small>${start} <span class="empMed">to</span> ${end}</small>
            </p>
        `;
        // Prizes
        if (this.quiz.prizes && this.quiz.prizes.length > 0) {
            let prizeMarkup: string = '';
            for (let i = 0; i < this.quiz.prizes.length; i++) {
                prizeMarkup += `
                <li>
                    <span class="rank">${this.gameService.nth(i+1)}</span>
                    <span class="name">${this.quiz.prizes[i].name}</span>
                    <span class="desc">${this.quiz.prizes[i].description}</span>
                </li>
                `;
            }
            this.dialogDetails.markup += `<ul id="dialogListPrizes">${prizeMarkup}<ul>`;
            this.dialogDetails.icon = 'fas fa-gift';
        }
    }

    // Clickthroughs ---

    clickAccount(): void {
        // If account, dialog account, else dialog auth (register/login)
        this.dialogService.trigger({type: this.account ? 'account' : 'auth'});
    }

    clickSponsor(): void {
        if (this.quiz.sponsor_iframe) {
            const d: any = { type: 'webview', title: 'Sponsor', webview: this.quiz.sponsor_link};
            this.dialogService.trigger(d);
            return;
        }
        const win = window.open(this.quiz.sponsor_link, '_blank');
        win.focus();
    }

    // Attempts ---

    goButton(): void {
        // If Account & Player Stats, make attempt, else dialog auth (register/login)
        if (this.account && this.playerStats) {
            this.makeAttempt();
        } else {
            this.dialogService.trigger({type: 'auth'});
            this.snackbarService.trigger('Register or login to begin.');
        }
    }

    makeAttempt(): void {
        const isVideoInterval: boolean = ((this.playerStats.total_attempts + 1) % this.quiz.rewarded_video_interval) === 0;
        const showVideo: boolean = this.playerStats.total_attempts === 0 || isVideoInterval;
        const subRoute: string = showVideo ? 'video' : '/game/start';
        this.router.navigate([`/g/${this.hash}/${subRoute}`]);
    }
}
